<template>
  <div>
    <back-nav to="vehiclePhotos" />
    <v-form v-model="isValid">
      <div class="tw-py-2">
        <div
          class="
            md:tw-py-4
            tw-grid tw-grid-cols-1
            md:tw-grid-cols-2
            tw-gap-2
            md:tw-gap-8
          "
        >
          <div>
            <h2 class="tw-text-xl tw-mt-2 c-gray-text">Special notes</h2>
            <hr />
            <p class="tw-py-2 md:tw-py-4">
              1.The estimate has been limited to work rendered necessary as the
              direct result of the accident. <br />
              2.If during the course of repairs, it is found that any increase
              over the estimate is required, further authority must be obtained
              before proceeding.
              <br />
              3.During the course of repairs, should it be found necessary to
              fit replacement parts due to wear and tear and/or mechanical
              breakdown, authority must be obtained directly from the insured.
              <br />
              4.Copies of spares issue notes to accompany final invoice. Failure
              to comply may prejudice payment. Vehicle to be re-inspected before
              release.
            </p>
          </div>
          <div>
            <h2 class="tw-text-xl tw-mt-2 c-gray-text">Repair Guidelines</h2>
            <hr />
            <div class="tw-py-2 md:tw-py-4">
              <p>
                Strip the vehicle as required. Remove and renew the listed
                items. set adjust and spray paint the areas affected to match
                the original color code.
              </p>
            </div>
          </div>
        </div>
        <div
          class="
            md:tw-py-4
            tw-grid tw-grid-cols-1
            md:tw-grid-cols-2
            tw-gap-2
            md:tw-gap-8
          "
        >
          <div class="col-span-2">
            <div class="tw-mt-2 md:tw-mt-6">
              <label class="c-gray-text">Region</label>
              <v-text-field v-model="clusterName" disabled></v-text-field>
            </div>
            <div class="tw-mt-2 md:tw-mt-6">
              As an assessor do you agree to the contents of this document as
              captured by you and the help your team? If you do check below.
            </div>
            <div class="tw-mt-2 md:tw-mt-6">
              <label>Assessor's Signature</label>
              <v-text-field
                v-model="assessorName"
                placeholder="Full Name"
                :rules="[required('Full Name')]"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-center">
        <div
          class="
            tw-w-2/3
            md:tw-w-1/3
            tw-py-4
            md:tw-py-10
            tw-space-x-2 tw-flex tw-justify-center
          "
        >
          <v-btn
            color="primary"
            outlined
            @click="$root.$emit('nav-back-to', { currentTab: 'vehiclePhotos' })"
            >Previous</v-btn
          >
          <v-btn color="primary" @click="dialog = true" :disabled="!isValid"
            >Preview</v-btn
          >
          <v-btn
            color="warning"
            :disabled="!isValid"
            :loading="loading"
            @click="submit"
            >Submit</v-btn
          >
        </div>
      </div>
    </v-form>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="1200"
    >
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="mdi-chat-processing" :is-image="false"
          >Submitted Bid Form</card-title
        >
        <v-row class="tw-py-5 md:tw-py-10">
          <v-col class="mx-auto" cols="12" md="12">
            <bid-template-preview
              :is-garage="false"
              :booking="{
                ...booking,
                ...{
                  biddingForm: {
                    ...$store.getters['bidForm/biddingForm'],
                    ...{ assessorName },
                  },
                },
              }"
            />
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BackNav from '@/components/BackNav'
import Validations from '@/utils/Validations'
import axios from 'axios'
import BidTemplatePreview from '@/components/assessment-center/bids-submitted/bidTemplatePreview'
import CardTitle from '@/components/CardTitle'
import store from '@/store'

export default {
  name: 'specialNotes',
  props: ['booking'],
  components: { CardTitle, BidTemplatePreview, BackNav },
  data() {
    return {
      isValid: false,
      repairType: null,
      clusterName: store.getters['auth/authenticatedUser']['custom:region'],
      assessorName: '',
      ...Validations,
      loading: false,
      dialog: false,
      regions: [],
      assessmentStatus: 'ASSIGNED_TO_CLUSTER',
    }
  },
  mounted() {
    this.repairType = this.booking.biddingTemplate.repairType
    this.assessorName = this.booking.biddingTemplate.assessorName
    this.fetchRegions()
  },
  methods: {
    fetchRegions() {
      axios
        .get('/motor-assessment/api/regions')
        .then((response) => {
          let regions = response.data.data
          this.regions = regions.map((region) => region.name)
        })
        .catch(() => {
          this.showSnackbarMessage()
        })
    },
    submit() {
      this.loading = true
      const payload = {
        ...this.$store.getters['bidForm/biddingForm'],
        ...{ assessorName: this.assessorName, clusterName: this.clusterName },
      }
      axios
        .post(
          `/motor-assessment/api/bidding-template/${this.booking.id}/BIDDING_TEMPLATE_DONE/?assessmentStatus=${this.assessmentStatus}`,
          payload
        )
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message: 'Bid form generation successful',
              variant: 'success',
            })
            this.$store.commit('bidForm/clearBiddingForm')
            this.$router.push({ name: 'MajorAccident' })
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: response.data.msg,
              variant: 'error',
            })
            this.loading = false
          }
        })
        .catch(() => {
          this.showSnackbarMessage()
        })
    },
    showSnackbarMessage: function () {
      this.$store.commit('SNACKBAR', {
        state: 'failed',
        message: 'AN Error Occurred, try again',
        variant: 'error',
      })
      this.loading = false
    },
  },
}
</script>
