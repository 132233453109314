import axios from 'axios'

export default {
  mounted() {
    this.$nextTick(function () {
      this.updateForm()
    })
  },
  watch: {
    biddingTemplate: function () {
      this.updateForm()
    },
  },
  methods: {
    processStep: function () {
      this.loading = true
      if (this.nextSection === 'to-vehicle-photos') {
        this.$store.commit('bidForm/updateBiddingForm', {
          repairs: this.parts,
          additionalInformation: this.additionalInformation,
        })
      } else {
        this.$store.commit('bidForm/updateBiddingForm', this.form)
      }
      axios
        .post(
          `/motor-assessment/api/bidding-template/${this.booking.id}/BIDDING_TEMPLATE_IN_PROGRESS`,
          this.$store.getters['bidForm/biddingForm']
        )
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.showSnackbarMessage('success', 'Progress saved', 'success')
            this.$emit(this.nextSection)
          } else {
            this.showSnackbarMessage('failed', response.data.msg, 'error')
            this.loading = false
          }
        })
        .catch(() => {
          this.showSnackbarMessage(
            'failed',
            'An error occurred, try again',
            'error'
          )
          this.loading = false
        })
    },

    showSnackbarMessage: function (state, message, variant) {
      this.$store.commit('SNACKBAR', {
        state: state,
        message: message,
        variant: variant,
      })
    },
  },
}
