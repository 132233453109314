<template>
  <div class="c-bg-gray rounded">
    <template v-if="photos && photos.length > 0">
      <div
        id="images-wrapper"
        class="tw-w-full tw-flex tw-flex-wrap"
        v-viewer="options"
      >
        <template v-for="(p, i) in photos">
          <div class="tw-p-2 md:tw-p-2 tw-w-1/2 md:tw-w-1/4" :key="i">
            <p class="tw-mb-0 tw-font-semibold">
              <small> Photo {{ i + 1 }} </small>
            </p>
            <div class="tw-relative img-container">
              <img
                class="tw-rounded-xl tw-w-full"
                :src="p.s3ImageUrl ? p.s3ImageUrl : p.imageBase64"
                :alt="p.imageName"
              />

              <div class="middle tw-cursor-pointer" @click="show">
                <v-icon>mdi-eye</v-icon>
                <small> view</small>
              </div>
            </div>
            <p class="tw-text-wrap tw-opacity-80 tw-mt-2" v-if="p.imageComment">
              <small>{{ p.imageComment }}</small>
            </p>
          </div>
        </template>
      </div>
    </template>
    <div v-else>
      <div class="tw-bg-yellow-100 tw-p-3 tw-rounded">
        <p>No uploaded photos</p>
      </div>
    </div>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

export default {
  name: 'Images',
  props: ['photos'],
  data() {
    return {
      options: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: false,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: false,
        keyboard: false,
      },
    }
  },
  methods: {
    show() {
      const imageViewer = this.$el.querySelector('#images-wrapper').$viewer
      imageViewer.show()
    },
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
}
</script>

<style scoped>
#images-wrapper img {
  width: 100% !important;
  height: 200px !important;
  max-height: 200px;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.img-container:hover img {
  opacity: 0.4;
}

.img-container:hover .middle {
  opacity: 1;
}
</style>
