<template>
  <div
    id="back-nav"
    class="tw-w-full tw-mt-0 md:tw-mb-2 tw-flex tw-justify-end"
    @click="navTo"
  >
    <div
      class="
        tw-flex tw-w-20 tw-cursor-pointer tw-items-center tw-content-center
      "
    >
      <v-icon class="c-bg-blue tw-rounded-full tw-w-6 tw-h-6" color="#fff" small
        >mdi-arrow-left</v-icon
      >
      <div class="c-gray-text tw-ml-2 tw-text-sm">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackNav',
  props: {
    to: String,
    default: null,
  },
  methods: {
    navTo() {
      if (this.to === null) this.$router.go(-1)
      this.$root.$emit('nav-back-to', { currentTab: this.to })
    },
  },
}
</script>
