<template>
  <div class="tw-flex tw-content-between tw-flex-wrap tw-py-2 md:tw-py-4">
    <v-card
      class="
        tw-w-full tw-rounded-lg
        c-bg-gray
        tw-flex tw-justify-center tw-flex-wrap
      "
      :flat="true"
    >
      <div
        class="image-preview tw-px-8 md:tw-px-12 tw-py-2"
        v-if="imageData.length > 0"
      >
        <div
          v-if="allowDelete"
          class="
            tw-w-full tw-flex tw-justify-end tw-absolute tw--ml-20 tw--mt-3
          "
        ></div>
        <img class="preview" :src="imageData" alt="img" />
        <div class="overlay overlay_top">
          <div class="tw-flex tw-flex-col tw-text-white">
            <div class="tw-flex tw-flex-row tw-cursor-pointer">
              <v-icon
                class="tw-justify-self-center tw-align-self-center"
                color="white"
                >mdi-eye</v-icon
              >
              <p class="tw-basis-2/4 tw-ml-3 tw-mt-3">View Image</p>
            </div>
            <div
              @click="deleteImage"
              class="tw-flex tw-flex-row tw-cursor-pointer"
            >
              <v-icon
                class="tw-justify-self-center tw-align-self-center"
                color="white"
                >mdi-close-circle</v-icon
              >
              <p class="tw-justify-self-center tw-ml-3 tw-mt-3">Delete</p>
            </div>
          </div>
        </div>
      </div>
      <label
        v-else
        class="
          tw-flex tw-flex-col tw-items-center tw-px-8 tw-justify-center
          md:tw-px-12
          tw-py-4
          md:tw-py-8
          tw-cursor-pointer
        "
      >
        <p class="c-blue-text tw-mt-3 tw-px-3">
          <v-icon
            class="c-bg-blue tw-rounded-full tw-w-6 tw-h-6 tw-mr-2"
            color="#fff"
            >mdi-plus</v-icon
          >Add Image(s)
        </p>
        <p class="tw-text-xs tw-text-center tw-text-gray-600">
          Upload 3 to 20 Photos (Each 5 MB or Less)
        </p>
        <p class="tw-text-xs tw-text-center tw-text-gray-600">
          Upload a minimum of 3 and a maximum of 20 photos
        </p>
        <input
          type="file"
          :id="name"
          class="tw-hidden"
          @change="previewImage"
          accept="image/*"
        />
        <v-text-field
          class=""
          v-model="image"
          :rules="rules"
          style="display: none"
        ></v-text-field>
      </label>
    </v-card>

    <v-text-field
      v-if="imageData.length > 0"
      id="comment"
      v-model="comment"
      placeholder="Type a comment"
      @blur="handleComment"
    ></v-text-field>
  </div>
</template>

<script>
import { objIsEmpty } from '@/utils/helpers'

export default {
  name: 'FileUpload',
  props: {
    rules: {
      type: Array,
      required: false,
    },
    name: String,
    addedImage: {
      type: Object,
      required: false,
    },
    index: Number,
  },
  data() {
    return {
      imageData: '',
      image: '',
      comment: '',
      allowDelete: true,
      file: {},
      result: {},
      reader: {},
      scale: 100,
      quality: 20,
    }
  },
  watch: {
    image(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    if (!objIsEmpty(this.addedImage)) {
      this.image = this.addedImage
      this.imageData = this.addedImage.imageBase64
      this.comment = this.addedImage.imageComment
    }
  },
  methods: {
    previewImage(event) {
      const input = event.target
      // get the file

      if (input.files && input.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.file = input.files[0]
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          const FileSize = this.file.size / 1024 / 1024
          if (FileSize > 5) {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'Image is greater than 5 MB',
              variant: 'error',
            })
            return
          }

          // Make new FileReader
          this.reader = new FileReader()
          // Convert the file to base64 text
          this.reader.readAsDataURL(this.file)
          // on reader load something...
          this.reader.onload = this.fileOnLoad
        } else {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: 'Not Valid Image',
            variant: 'error',
          })
        }
      }
    },
    /*
       Draw And Compress The Image
       @params {String} imgUrl
     */
    drawImage(imgUrl, type) {
      // Recreate Canvas Element
      const canvas = document.createElement('canvas')
      // Set Canvas Context
      const ctx = canvas.getContext('2d')
      // Create New Image
      const img = new Image()
      img.src = imgUrl
      img.onload = () => {
        // Image Size After Scaling
        const scale = this.scale / 100
        const width = img.width * scale
        const height = img.height * scale
        // Set Canvas Height And Width According to Image Size And Scale
        canvas.setAttribute('width', width)
        canvas.setAttribute('height', height)
        ctx.drawImage(img, 0, 0, width, height)
        // Quality Of Image
        const quality = this.quality ? this.quality / 100 : 1
        // If all files have been proceeded
        const base64 = canvas.toDataURL(type, quality)
        const fileName = this.result.file.name

        const objToPass = {
          canvas,
          original: this.result,
          compressed: {
            base64,
            name: fileName,
            file: this.buildFile(base64, type),
          },
        }

        objToPass.compressed.size = `${Math.round(
          objToPass.compressed.file.size / 1000
        )} kB`
        objToPass.compressed.type = type
        this.imageData = objToPass.compressed.base64
        this.image = {
          imageBase64: this.imageData,
          imageName: fileName || this.name,
        }
      }
    },
    /*
        When The File in loaded
      */
    fileOnLoad() {
      // The File
      const { file } = this
      // Make a fileInfo Object
      const fileInfo = {
        name: file.name,
        type: file.type,
        size: `${Math.round(file.size / 1000)} kB`,
        base64: this.reader.result,
        file,
      }
      // Push it to the state
      this.result = fileInfo
      // DrawImage
      this.drawImage(this.result.base64, file.type)
    },
    // Convert Blob To File
    buildFile(blob, type) {
      return new Blob([blob], { type })
    },
    deleteImage() {
      this.imageData = ''
      this.image = {}
      this.file = {}
      this.result = {}
      this.reader = {}
      this.$emit('delete-image')
      this.comment = ''
    },
    handleComment() {
      this.$emit('add-comment', this.comment, this.index, this.image)
    },
  },
}
</script>

<style scoped>
img.preview {
  width: 150px;
  background-color: white;
  /*border: 1px solid #DDD;*/
  object-fit: cover;
  /*padding: 5px;*/
}

.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay_top {
  inset: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.image-preview:hover .overlay_top {
  transform: scale(1);
}
</style>
