<template>
  <v-container align="center" justify="center">
    <v-row class="py-4 mx-auto">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Major Accident</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12">
        <v-card elevation="2" class="tw-px-3 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <card-title
            :icon="require('@/assets/img/assesment-center/accident_icon.png')"
            >Bid Form</card-title
          >
          <template v-if="!loading">
            <div class="tw-mt-3 tw-flex tw-justify-center">
              <v-stepper
                class="tw-w-full md:tw-w-2/3 tw-text-sm"
                alt-labels
                flat
              >
                <v-stepper-header>
                  <v-stepper-step step="1" :complete="progress > 0">
                    <span :class="[progress > 0 ? 'c-blue-text' : '']"
                      >Customer Details</span
                    >
                  </v-stepper-step>

                  <v-divider
                    :color="progress > 0 ? 'secondary' : ''"
                  ></v-divider>

                  <v-stepper-step step="2" :complete="progress > 35">
                    <span :class="[progress > 35 ? 'c-blue-text' : '']"
                      >+ Repair Parts</span
                    >
                  </v-stepper-step>

                  <v-divider
                    :color="progress > 35 ? 'secondary' : ''"
                  ></v-divider>

                  <v-stepper-step step="3" :complete="progress > 67">
                    <span :class="[progress > 67 ? 'c-blue-text' : '']"
                      >Photo Uploads</span
                    >
                  </v-stepper-step>

                  <v-divider
                    :color="progress > 67 ? 'secondary' : ''"
                  ></v-divider>

                  <v-stepper-step :complete="progress === 100" step="4">
                    <span :class="[progress > 100 ? 'c-blue-text' : '']"
                      >Select Garage</span
                    >
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </div>
            <div class="tw-mt-2 md:tw-mt-4">
              <transition name="fade" mode="out-in" appear :duration="500">
                <keep-alive>
                  <component
                    :is="current"
                    :booking="booking"
                    :bidding-template="biddingTemplate"
                    :bodyTypes="bodyTypes"
                    @to-repair-parts="toRepairParts"
                    @to-vehicle-photos="toVehiclePhotos"
                    @to-special-notes="toSpecialNotes"
                  ></component>
                </keep-alive>
              </transition>
            </div>
          </template>
          <div v-else class="tw-w-full tw-flex tw-justify-center tw-py-12">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import customerDetails from '@/components/assessment-center/major-accident/customerDetails'
import repairParts from '@/components/assessment-center/major-accident/repairParts'
import vehiclePhotos from '@/components/assessment-center/major-accident/vehiclePhotos'
import specialNotes from '@/components/assessment-center/major-accident/specialNotes'
import axios from 'axios'

export default {
  name: 'BidFormPreparation',
  components: {
    CardTitle,
    customerDetails,
    vehiclePhotos,
    repairParts,
    specialNotes,
  },
  data() {
    return {
      progress: 0,
      current: null,
      loading: true,
      biddingTemplate: {},
      bodyTypes: [],
    }
  },
  computed: {
    booking() {
      return this.$store.getters['bidForm/bookings'].find(
        (i) => i.id === this.$route.params.bookingId
      )
    },
  },
  created: function () {
    this.getBiddingTemplate()
    this.getVehicleTypes()
  },
  mounted() {
    if (this.booking === undefined) {
      this.getBooking()
    } else {
      this.loading = false
      this.current = customerDetails
    }

    this.$root.$on('nav-back-to', (data) => {
      this.current = data.currentTab
      const states = {
        customerDetails: 0,
        repairParts: 36,
        vehiclePhotos: 68,
        specialNotes: 100,
      }
      this.progress = states[data.currentTab]
    })
  },
  methods: {
    getBooking: function () {
      axios
        .get(`/motor-assessment/api/booking/${this.$route.params.bookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          this.$store.commit('bidForm/updateBookings', [res.data.data])
          this.current = customerDetails
          this.loading = false
        })
    },

    getBiddingTemplate: function () {
      axios
        .get(
          `/motor-assessment/api/bidding-template/${this.$route.params.bookingId}`
        )
        .then((res) => {
          if (res.data.success && res.data.data !== null) {
            this.biddingTemplate = res.data.data
            this.$store.commit(
              'bidForm/updateBiddingForm',
              this.biddingTemplate
            )
          }
        })
    },

    getVehicleTypes: function () {
      axios
        .get(`/motor-assessment/api/motor/vehicle/body-type`)
        .then((res) => {
          this.bodyTypes = res.data.data
        })
        .catch((err) => {
          this.$store.commit('index/SNACKBAR', {
            state: 'failed',
            message: `${err.response.data.msg}`,
            variant: 'error',
          })
        })
    },

    toRepairParts() {
      this.current = repairParts
      this.progress = 36
    },
    toVehiclePhotos() {
      this.current = vehiclePhotos
      this.progress = 68
    },
    toSpecialNotes() {
      this.current = specialNotes
      this.progress = 100
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  & > * {
    transition-duration: 100ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.6, 0.15, 0.35, 0.8);
  }
}

$delay: 60ms;
$delayStep: 60ms;

.fade-enter,
.fade-leave-to {
  & > * {
    opacity: 0;
    transform: translateY(40px);
  }
}
.fade-enter-active {
  & > * {
    &:nth-child(2) {
      transition-delay: $delay;
    }
    &:nth-child(3) {
      transition-delay: $delay + $delayStep;
    }
  }
}
.fade-leave-active {
  & > * {
    &:nth-child(1) {
      transition-delay: $delay + $delayStep;
    }
    &:nth-child(2) {
      transition-delay: $delay;
    }
  }
}
</style>
